import { SharedApiModule } from '@/shared';
import {
  IAdditionalServiceCreateBody, ICreateAdditionalServiceOrder,
  ICreatedSubscriptionOrder,
  IDedicatedSubscriptionCreateBody, IPricesCalculateParams, IResponseCalculatePrice,
  ISharedSubscriptionCreateBody,
} from '@/entities/subscriptionCreate/types';
import store from '@/store';
import { AxiosResponse } from 'axios';

const subscriptionCreate = (subscriptionCreateBody: ISharedSubscriptionCreateBody | IDedicatedSubscriptionCreateBody, recaptchaToken: string) =>
  SharedApiModule.HttpClients.httpClientV6.post(
    'subscription/create',
    subscriptionCreateBody,
    {
      headers: {
        Authorization: `Bearer ${store.getters.isPersonalAccount ? store.state.accessToken : store.state.teams.teamToken}`,
        'recaptcha-token': recaptchaToken,
      },
    },
  ) as Promise<AxiosResponse<ICreatedSubscriptionOrder>>;

const additionalServiceCreate = (subscriptionCreateBody: IAdditionalServiceCreateBody, recaptchaToken: string) =>
  SharedApiModule.HttpClients.httpClientV6.post(
    'subscription/additional-service',
    subscriptionCreateBody,
    {
      headers: {
        Authorization: `Bearer ${store.state.accessToken}`,
        'recaptcha-token': recaptchaToken,
      },
    },
  ) as Promise<AxiosResponse<ICreateAdditionalServiceOrder>>;

const sseObserverPayLink = (ticket: string) => new Promise((resolve, reject) => {
  const sseObserver = SharedApiModule.SseObserver.sseInstance('paylink', `${process.env.VUE_APP_API_URL_V6 as string}order-pub/paylink?ticket=${ticket}`);
  sseObserver.addEventListener('paylink', (event) => {
    const messageEvent = event as MessageEvent<string>;
    resolve(messageEvent.data);
    sseObserver.close();
  });
  sseObserver.onerror = function(error) {
    sseObserver.close();
    reject(error);
  };
}) as Promise<string>;

const fetchPricesCalculate = (params: IPricesCalculateParams) => SharedApiModule.HttpClients.httpClientV3.post(
  'prices/calculate/',
  params,
  {
    headers: {
      Authorization: `Bearer ${store.state.accessToken}`,
    },
  },
) as Promise<AxiosResponse<IResponseCalculatePrice>>;

export { subscriptionCreate, additionalServiceCreate, sseObserverPayLink, fetchPricesCalculate };