import { SharedApiModule } from '@/shared'
import store from '@/store';
import { AxiosResponse } from 'axios';
import { ISharedPlans } from '@/entities/sharedPlans/types';
import { StartPlanProductId, UnlimitedPlanProductId } from '@/shared/products/types';

const fetchPlans = () => SharedApiModule.HttpClients.httpClientV3.get('/prices/plans/', {
  headers: {
    Authorization: `Bearer ${store.state.accessToken}`,
  },
}) as Promise<AxiosResponse<{[key in StartPlanProductId | UnlimitedPlanProductId]: ISharedPlans<StartPlanProductId | UnlimitedPlanProductId>}>>

export { fetchPlans }