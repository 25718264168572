import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/banners/security_2.png'


const _hoisted_1 = { class: "flex gap-[72px] sm:gap-2 xs:gap-2 items-center justify-center notifying-banner-gb px-[30px] xs:flex-col xs:p-3" }
const _hoisted_2 = { class: "flex flex-col text-sm text-text-color-bg py-3.5 xs:py-0" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "font-medium" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "whitespace-nowrap" }
const _hoisted_7 = {
  key: 1,
  href: "https://getblock.io/blog/big-2025-dedicated-node-upgrade/",
  target: "_blank",
  class: "xs:w-full"
}
const _hoisted_8 = { class: "whitespace-nowrap" }

import { useTimestamp } from '@/composables/useTimestamp';
import { GetBlockUIButton } from '@getblockui/core';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'NotifyingUsersAboutPriceChangesBanner',
  setup(__props) {

const ChangePriceDateOption = {
  day: 25,
  month: 3,
  year: 2025,
  hour: 8,
  timeZone: 'UTC',
};
const { getDateUTC, parseDateToTimestampUTC, formattedDateToStringFromDateObjectUTC, numberOfMillisecondsFromFormat } = useTimestamp();
const { t } = useI18n();

const intervalId = ref<ReturnType<typeof setInterval> | null>(null);

const ChangePriceDate = new Date(Date.UTC(ChangePriceDateOption.year, ChangePriceDateOption.month - 1, ChangePriceDateOption.day, ChangePriceDateOption.hour));
const currentDate = getDateUTC();

const ChangePriceDateTimestamp = parseDateToTimestampUTC(formattedDateToStringFromDateObjectUTC(ChangePriceDate));
const currentDateTimestamp = ref<number>(parseDateToTimestampUTC(currentDate));

const isPriceChange = computed(() => currentDateTimestamp.value >= ChangePriceDateTimestamp);
const getTitle = computed(() => isPriceChange.value ? t('notifying-banner.after-price-change.title') : t('notifying-banner.before-price-change.title'));
const getSubscription = computed(() => {
  return isPriceChange.value ? {
    1: t('notifying-banner.after-price-change.subscription.1'),
    2: t('notifying-banner.after-price-change.subscription.2'),
  } : {
    1: t('notifying-banner.before-price-change.subscription.1'),
    2: t('notifying-banner.before-price-change.subscription.2'),
  };
});

const checkHowMuchTimeLeft = () => {
  const diff = ChangePriceDateTimestamp - currentDateTimestamp.value;
  const isTenMinutesLeft = diff < numberOfMillisecondsFromFormat.minute(10)
  if (isTenMinutesLeft) intervalStart()
}

const intervalStart = () => {
  intervalId.value = setInterval(() => {
    currentDateTimestamp.value = parseDateToTimestampUTC(getDateUTC());
    if (currentDateTimestamp.value >= ChangePriceDateTimestamp && intervalId.value) clearInterval(intervalId.value)
  }, numberOfMillisecondsFromFormat.second(30))
}

onMounted(() => {
  checkHowMuchTimeLeft()
})

onUnmounted(() => {
  if (intervalId.value) clearInterval(intervalId.value)
})



return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "overflow-hidden flex-shrink-0 xs:hidden sm:hidden max-w-[102px] max-h-[64px] flex items-center justify-center" }, [
      _createElementVNode("img", {
        width: "102",
        height: "64",
        alt: "GetBlock.io",
        src: _imports_0
      })
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(getTitle.value), 1),
      _createElementVNode("p", _hoisted_4, [
        _createElementVNode("span", null, _toDisplayString(getSubscription.value['1']), 1),
        (!isPriceChange.value)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(` March ${ChangePriceDateOption.day}`), 1))
          : _createCommentVNode("", true),
        _createElementVNode("span", null, _toDisplayString(getSubscription.value['2']), 1)
      ])
    ]),
    (!isPriceChange.value)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: "/dedicated-node-create",
          class: "xs:w-full"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(GetBlockUIButton), {
              "size-type": "small",
              "color-type": "secondary",
              class: "flex items-center justify-center w-full"
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('notifying-banner.before-price-change.button')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : (_openBlock(), _createElementBlock("a", _hoisted_7, [
          _createVNode(_unref(GetBlockUIButton), {
            "size-type": "small",
            "color-type": "secondary",
            class: "flex items-center justify-center w-full"
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('notifying-banner.after-price-change.button')), 1)
            ]),
            _: 1
          })
        ]))
  ]))
}
}

})