<script lang="ts" setup>
import { useTimestamp } from '@/composables/useTimestamp';
import { GetBlockUIButton } from '@getblockui/core';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const ChangePriceDateOption = {
  day: 25,
  month: 3,
  year: 2025,
  hour: 8,
  timeZone: 'UTC',
};
const { getDateUTC, parseDateToTimestampUTC, formattedDateToStringFromDateObjectUTC, numberOfMillisecondsFromFormat } = useTimestamp();
const { t } = useI18n();

const intervalId = ref<ReturnType<typeof setInterval> | null>(null);

const ChangePriceDate = new Date(Date.UTC(ChangePriceDateOption.year, ChangePriceDateOption.month - 1, ChangePriceDateOption.day, ChangePriceDateOption.hour));
const currentDate = getDateUTC();

const ChangePriceDateTimestamp = parseDateToTimestampUTC(formattedDateToStringFromDateObjectUTC(ChangePriceDate));
const currentDateTimestamp = ref<number>(parseDateToTimestampUTC(currentDate));

const isPriceChange = computed(() => currentDateTimestamp.value >= ChangePriceDateTimestamp);
const getTitle = computed(() => isPriceChange.value ? t('notifying-banner.after-price-change.title') : t('notifying-banner.before-price-change.title'));
const getSubscription = computed(() => {
  return isPriceChange.value ? {
    1: t('notifying-banner.after-price-change.subscription.1'),
    2: t('notifying-banner.after-price-change.subscription.2'),
  } : {
    1: t('notifying-banner.before-price-change.subscription.1'),
    2: t('notifying-banner.before-price-change.subscription.2'),
  };
});

const checkHowMuchTimeLeft = () => {
  const diff = ChangePriceDateTimestamp - currentDateTimestamp.value;
  const isTenMinutesLeft = diff < numberOfMillisecondsFromFormat.minute(10)
  if (isTenMinutesLeft) intervalStart()
}

const intervalStart = () => {
  intervalId.value = setInterval(() => {
    currentDateTimestamp.value = parseDateToTimestampUTC(getDateUTC());
    if (currentDateTimestamp.value >= ChangePriceDateTimestamp && intervalId.value) clearInterval(intervalId.value)
  }, numberOfMillisecondsFromFormat.second(30))
}

onMounted(() => {
  checkHowMuchTimeLeft()
})

onUnmounted(() => {
  if (intervalId.value) clearInterval(intervalId.value)
})


</script>

<template>
  <div class="flex gap-[72px] sm:gap-2 xs:gap-2 items-center justify-center notifying-banner-gb px-[30px] xs:flex-col xs:p-3">
    <div class="overflow-hidden flex-shrink-0 xs:hidden sm:hidden max-w-[102px] max-h-[64px] flex items-center justify-center">
      <img width="102" height="64" alt="GetBlock.io" src="@/assets/images/banners/security_2.png" />
    </div>
    <div class="flex flex-col text-sm text-text-color-bg py-3.5 xs:py-0">
      <p class="font-bold">{{ getTitle }}</p>
      <p class="font-medium">
        <span>{{ getSubscription['1'] }}</span>
        <span v-if="!isPriceChange"> {{ ` March ${ChangePriceDateOption.day}` }}</span>
        <span>{{ getSubscription['2'] }}</span>
      </p>
    </div>
    <router-link v-if="!isPriceChange" to="/dedicated-node-create" class="xs:w-full">
      <GetBlockUIButton size-type="small" color-type="secondary" class="flex items-center justify-center w-full">
        <p class="whitespace-nowrap">{{ $t('notifying-banner.before-price-change.button') }}</p>
      </GetBlockUIButton>
    </router-link>
    <a v-else href="https://getblock.io/blog/big-2025-dedicated-node-upgrade/" target="_blank" class="xs:w-full">
      <GetBlockUIButton size-type="small" color-type="secondary" class="flex items-center justify-center w-full">
        <p class="whitespace-nowrap">{{ $t('notifying-banner.after-price-change.button') }}</p>
      </GetBlockUIButton>
    </a>
  </div>
</template>

<style lang="scss" scoped>
.notifying-banner-gb {
  background-image: url("@/assets/images/banners/notifying_banner_bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
}
</style>