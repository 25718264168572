import { SharedApiModule } from '@/shared'
import store from '@/store';
import { AxiosResponse } from 'axios';
import { RequestsPackId } from '@/shared/products/types';
import { IRequestPackages } from '@/entities/requestPackage/types';

const fetchPacks = () => SharedApiModule.HttpClients.httpClientV3.get('/prices/packs/', {
  headers: {
    Authorization: `Bearer ${store.state.accessToken}`,
  },
}) as Promise<AxiosResponse<{[key in RequestsPackId]: IRequestPackages}>>

export { fetchPacks }